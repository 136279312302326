import React from "react";

import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <div className="bg-primary pt-5 pb-5 text-light mt-5">
      <Container>
        <h5>Copyright &copy; 2020 Kedaf Enterprises</h5>
        <h5>Designed by Zing Media</h5>
      </Container>
    </div>
  );
};

export default Footer;

import React from "react";
import Title from "../../layout/Title";

import ContactForm from "./ContactForm";
import ContactItems from "./ContactItems";

import { Container, Row, Col } from "react-bootstrap";

const ContactSection = () => {
  return (
    <div id="contact">
      <Title text="Contact Us" color="primary" />

      <h3 className="text-center text-primary mt-4 mb-4">
        Get in Touch with Us!
      </h3>

      <Container>
        <Row>
          <Col lg>
            <ContactItems />
          </Col>
          <Col lg>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactSection;

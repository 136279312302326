import React from "react";

import "./styling/App.scss";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

import { BrowserRouter as Router } from "react-router-dom";

import Routing from "./components/routing/Routing";

const App = () => {
  return (
    <Router>
      <Header />

      <Routing />

      <Footer />
    </Router>
  );
};

export default App;

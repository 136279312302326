import React from "react";

import { Container, Row, Col, Image } from "react-bootstrap";

import Title from "../../layout/Title";

import aboutImage from "../../../assets/images/about-img.jpg";

const AboutSection = () => {
  return (
    <div id="about">
      <Title text="About Us" color="primary" />

      <Container fluid className="mt-5 mb-5">
        <Row>
          <Col xl={8}>
            <h4>
              Our services focus on calculating required heating/cooling load
              calculations for residential homes. We also provide the design
              layout for ductwork as required. We are prepared to give an
              estimate for the service we provide by attending the site or from
              the drawings submitted. We select the right size furnaces and make
              recommendations to assist our clients in their choice of
              equipment. We strive to provide the highest quality service in the
              area and aim for 100% client satisfaction!
            </h4>

            <h4>
              Our work is done by a qualified residential air system designer
              using an up-to-date and accurate software program. We conduct our
              work based on provincial requirements and HRAI standards!
            </h4>
          </Col>

          <Col xl={4}>
            <Image fluid src={aboutImage} rounded />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutSection;

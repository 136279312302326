import React from "react";
import PropTypes from "prop-types";

import colors from "../../styling/variables.scss";

const MainBanner = ({ title, subtitle, bgImage, hasOverlay, overlay }) => {
  const styles = {
    background: bgImage ? `url(${bgImage})` : colors.primary,
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  return (
    <div style={styles}>
      <div className={`text-center ${hasOverlay && overlay}`}>
        <h1>{title}</h1>
        <h3>{subtitle}</h3>
      </div>
    </div>
  );
};

MainBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  bgImage: PropTypes.string,
  hasOverlay: PropTypes.bool,
  overlay: PropTypes.string,
};

MainBanner.defaultProps = {
  overlay: "primary",
};

export default MainBanner;

import designImage from "../assets/images/hvac-design-1.jpg";
import assessmentImage from "../assets/images/hvac-assessments-services-image.jpg";
import homeInspectionImage from "../assets/images/hvac-home-inspections-services-image.jpg";

const services = [
  {
    id: 0,
    title: "H.V.A.C. Design",
    description:
      "We provide you with accurate layout and sizing of ductwork as required, using AutoCad Program. We provide you plans for submittal to City Hall to obtain required permits.",
    image: designImage,
  },
  {
    id: 1,
    title: "Assessments and Client Recommendations",
    description:
      "We do assessments and provide you recommendations on what we think is best for your needs.",
    image: assessmentImage,
  },
  {
    id: 2,
    title: "Inspections",
    description:
      "We do site visits to ensure that the design will function properly as intended.  Your comfort is important to us so we do everything to make sure everything goes according to plan.",
    image: homeInspectionImage,
  },
];

export default services;

import React, { useState } from "react";

import { Form, Button, Spinner } from "react-bootstrap";
import axios from "axios";

const ContactForm = () => {
  const [data, setData] = useState({
    name: null,
    email: null,
    message: null,
  });

  const [status, setStatus] = useState(0);

  const onChange = (e) => {
    const { id, value } = e.target;

    setData((prevState) => ({ ...prevState, [id]: value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setStatus(1);

    try {
      setTimeout(async () => {
        const res = await axios.post(
          process.env.REACT_APP_CONTACTFORM_ENDPOINT,
          {
            data: { ...data },
          }
        );

        console.log(res.data);

        setStatus(2);
      }, 2000);
    } catch (error) {
      setStatus(3);
    }
  };
  return (
    <Form onSubmit={onSubmit}>
      <Form.Group controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          required
          placeholder="Your full name"
          onChange={onChange}
        />
      </Form.Group>

      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          required
          placeholder="Your email address"
          onChange={onChange}
        />
      </Form.Group>

      <Form.Group controlId="message">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          required
          rows="3"
          placeholder="Your message"
          onChange={onChange}
        />
      </Form.Group>

      {status === 3 && (
        <div className="text-danger mb-3">
          There seems to be a problem right now. Please try again later.
        </div>
      )}

      <Button variant="primary" type="submit" disabled={status !== 0}>
        {(status === 0 || status === 3) && "Send Message"}
        {status === 1 && (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Sending...
          </>
        )}

        {status === 2 && "Message Sent!"}
      </Button>
    </Form>
  );
};

export default ContactForm;

import React from "react";

import MainBanner from "../../layout/MainBanner";
import AboutSection from "./AboutSection";
import ServicesSection from "./ServicesSection";
import ContactSection from "./ContactSection";

import mainBannerImage from "../../../assets/images/main-home-bg.jpg";

const Home = () => {
  return (
    <div id="home">
      <MainBanner
        title="Welcome to Kedaf Enterprises"
        subtitle="Experts in Residential, Heating and Cooling Load Calculations and Ductwork Layout"
        bgImage={mainBannerImage}
        hasOverlay
        overlay="overlay-dark"
      />

      <AboutSection />
      <ServicesSection />
      <ContactSection />
    </div>
  );
};

export default Home;

import React from "react";

import { Container } from "react-bootstrap";

import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <Container className="mt-5 mb-5 text-center">
      <h2 className="text-dark mb-4">404 Not Found</h2>
      <h4 className="text-primary mb-4">
        Opps, looks like you came across a page that does not exist
      </h4>
      <Link to="/" className="btn btn-primary">
        Return to Home Page
      </Link>
    </Container>
  );
};

export default Page404;

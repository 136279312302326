import React from "react";

import { Navbar, Nav, Image } from "react-bootstrap";

import logo from "../../assets/logos/kedaf-logo.svg";

const Header = () => {
  return (
    <Navbar bg="primary" variant="dark" expand="md" sticky="top">
      <Navbar.Brand href="#home">
        <Image src={logo} className="header-logo-image" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav defaultActiveKey="#home" className="ml-auto">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#about">About</Nav.Link>
          <Nav.Link href="#services">Services</Nav.Link>
          <Nav.Link href="#contact">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;

import React from "react";

import { Container, Row, Col, Card } from "react-bootstrap";

import Title from "../../layout/Title";

import servicesData from "../../../utilites/servicesData";

const ServicesSection = () => {
  return (
    <div id="services">
      <Title text="Services" color="primary" />
      <Container fluid className="justify-content-center mb-5">
        <Row>
          {servicesData.map((service) => (
            <Col lg key={service.id} className="mt-5">
              <Card className="service-item">
                <Card.Img variant="top" src={service.image} />
                <Card.Body>
                  <Card.Title className="text-center">
                    {service.title}
                  </Card.Title>

                  <Card.Text>{service.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ServicesSection;

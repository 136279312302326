import React from "react";

import { FiMail, FiPhoneCall } from "react-icons/fi";

import { Row, Col } from "react-bootstrap";

const ContactItems = () => {
  return (
    <>
      <h3 className="contact-item mt-5">
        <Row>
          <Col lg={1}>
            <FiPhoneCall />
          </Col>
          <Col lg>
            <a href="tel:416-936-5968">(416)-936-5968</a>
            <br />
            <a href="tel:905-518-9272">(905)-518-9272</a>
          </Col>
        </Row>
      </h3>

      <h3 className="contact-item mt-5 mb-4">
        <Row>
          <Col lg={1}>
            <FiMail />
          </Col>
          <Col lg>
            <a href="mailto:info@kedafenterprises.com">
              info@kedafenterprises.com
            </a>
          </Col>
        </Row>
      </h3>
    </>
  );
};

export default ContactItems;

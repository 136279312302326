import React from "react";
import PropTypes from "prop-types";

const Title = ({ text, color }) => {
  return (
    <div className={`pt-4 pb-4 text-center text-light bg-${color}`}>
      <h1>{text}</h1>
    </div>
  );
};

Title.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Title.defaultProps = {
  color: "primary",
};

export default Title;
